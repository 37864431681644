import { fetchUtils } from "react-admin";

export const fetch = (url: string, options = {}) => {
  (options as any).user = {
    authenticated: true,
    // use the token from local storage
    token: `Bearer ${localStorage.getItem("token")}`,
  };
  return fetchUtils.fetchJson(url, options);
};
