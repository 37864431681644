const authProvider = {
  login: ({ username, password }: { username: string; password: string }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_URL}/admin/login`,
      {
        method: "POST",
        body: JSON.stringify({ login: username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((token) => {
        console.log("token", token);
        localStorage.setItem("token", token);
      })
      .catch((e) => {
        console.error(e);
        throw new Error("Network error");
      });
  },
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  checkError: (error: any) => {
    const status = error.status;
    if (status === 503 || status === 401) {
      localStorage.removeItem("token");
      return Promise.reject({ redirectTo: "/login" });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
};

export default authProvider;
