import {
  Create,
  Form,
  SelectInput,
  DateTimeInput,
  required,
  TextInput,
  FormDataConsumer,
  NumberInput,
  Validator,
  SaveButton,
} from "react-admin";
import CardContent from "@mui/material/CardContent";

import Grid from "@mui/material/Grid";
import { useLocation } from "react-router";
import { paymentBlockchains, paymentKinds, ranks } from "../../constants";

const getBlockchainTickers = (blockchain: any): string[] => {
  if (blockchain === "TRON") return ["USDT"];
  else if (blockchain === "ETHEREUM") return ["ETH", "USDT"];
  else if (blockchain === "BINANCE_SMART_CHAIN") return ["USDT"];
  else if (blockchain === "BITCOIN") return ["BTC"];
  return [];
};

const assets = [
  { value: "MAIN-USDT", label: "Main balance" },
  { value: "MAIN-ITE", label: "Token balance" },
  { value: "PARTNER-USDT", label: "Partner balance" },
];

export const PaymentsCreate = () => (
  <Create
    redirect="list"
    transform={(data: any & { time: Date }) => ({
      ...data,
      time: data.time.getTime(),
    })}
  >
    <CardContent>
      <Layout />
    </CardContent>
  </Create>
);

const validateTicker: Validator = (value: string, values) => {
  if (value && values.blockchain) {
    const tickers = getBlockchainTickers(values.blockchain);
    if ((tickers as any).includes(value)) return undefined;
    return "Ticker blockchain incompability";
  }
};
const Layout = () => {
  const { state } = useLocation();

  console.log("state", state)

  return (
    <Form sanitizeEmptyValues>
      <Grid container>
        <Grid item xs={12}>
          <SelectInput
            source="kind"
            variant="outlined"
            fullWidth
            defaultValue={state.kind ?? paymentKinds[0].value}
            choices={[paymentKinds[0], paymentKinds[1], paymentKinds[2]].map(
              (t) => ({ id: t.value, name: t.label })
            )}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth
            source="userId"
            defaultValue={state.userId}
            variant="outlined"
            validate={required()}
          />
        </Grid>
        <Grid item xs={4} container direction="column">
          <FormDataConsumer>
            {({ formData, ...rest }) => getFormLayoutByKind(formData as any)}
          </FormDataConsumer>
        </Grid>
        <Grid item xs={12}>
          <SaveButton label="Create" />
        </Grid>
      </Grid>
    </Form>
  );
};

const getFormLayoutByKind = ({
  kind,
  blockchain = "ETHEREUM",
  ticker = "USDT",
}: {
  kind: string;
  blockchain: string;
  ticker: string;
}): JSX.Element | null => {
  if (kind === "REPLENISHMENT") {
    return (
      <>
        <SelectInput
          fullWidth
          variant="outlined"
          source="ticker"
          defaultValue={getBlockchainTickers(blockchain)[0]}
          choices={getBlockchainTickers(blockchain).map((t) => ({
            id: t,
            name: t,
          }))}
          validate={[required(), validateTicker]}
        />
        <SelectInput
          fullWidth
          variant="outlined"
          source="blockchain"
          defaultValue={blockchain}
          choices={paymentBlockchains.map((t) => ({
            id: t.value,
            name: t.label,
          }))}
          validate={required()}
        />
        <TextInput
          source="price"
          fullWidth
          variant="outlined"
          validate={required()}
        />
        <TextInput
          source="amount"
          fullWidth
          variant="outlined"
          validate={required()}
        />
        <TextInput
          source="fee"
          fullWidth
          variant="outlined"
          validate={required()}
        />
        <TextInput
          fullWidth
          source="to"
          variant="outlined"
          defaultValue={"0x0000000000000000000000000000000000000000"}
          validate={required()}
        />
        <DateTimeInput
          fullWidth
          source="time"
          defaultValue={new Date()}
          variant="outlined"
          validate={required()}
        />
        <TextInput fullWidth source="hash" variant="outlined" />
        <NumberInput
          source="block"
          fullWidth
          variant="outlined"
          validate={required()}
          defaultValue={99}
        />
      </>
    );
  }

  if (kind === "WITHDRAWAL") {
    return (
      <>
        <SelectInput
          fullWidth
          variant="outlined"
          source="ticker"
          defaultValue={"USDT"}
          choices={["USDT"].map((t) => ({
            id: t,
            name: t,
          }))}
          validate={[required(), validateTicker]}
        />
        <SelectInput
          fullWidth
          variant="outlined"
          source="blockchain"
          defaultValue={blockchain}
          choices={paymentBlockchains
            .filter((b) => b.value !== "BITCOIN")
            .map((t) => ({
              id: t.value,
              name: t.label,
            }))}
          validate={required()}
        />
        <TextInput
          source="amount"
          fullWidth
          variant="outlined"
          validate={required()}
        />
        <TextInput
          source="fee"
          fullWidth
          variant="outlined"
          validate={required()}
        />
        <TextInput
          fullWidth
          source="to"
          variant="outlined"
          defaultValue={"0x0000000000000000000000000000000000000000"}
          validate={required()}
        />
        <DateTimeInput
          fullWidth
          source="time"
          defaultValue={new Date()}
          variant="outlined"
          validate={required()}
        />
        <TextInput
          fullWidth
          source="hash"
          variant="outlined"
          validate={required()}
        />
        <NumberInput
          source="block"
          fullWidth
          variant="outlined"
          validate={required()}
          defaultValue={99}
        />
      </>
    );
  }

  if (kind === "INVESTMENT") {
    return (
      <>
        <SelectInput
          fullWidth
          variant="outlined"
          source="asset"
          label="Balance"
          defaultValue={assets[0].value}
          choices={assets.map((t) => ({
            id: t.value,
            name: t.label,
          }))}
          validate={required()}
        />
        <TextInput
          source="amount"
          fullWidth
          variant="outlined"
          validate={required()}
        />
        <TextInput
          source="fee"
          fullWidth
          variant="outlined"
          validate={required()}
        />
        <DateTimeInput
          fullWidth
          source="time"
          defaultValue={new Date()}
          variant="outlined"
          validate={required()}
        />
      </>
    );
  }

  return null;
};
