import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import btcImg from "./BTC.svg";
import ethImg from "./ETH.svg";
import usdtImg from "./USDT.svg";
import { useQuery, useMutation } from "react-query";
import { PaymentMethodsApi } from "../../api";

const tickerImg: any = {
  BTC: btcImg,
  ETH: ethImg,
  USDT: usdtImg,
};

const Blockchain = {
  BITCOIN: "Bitcoin",
  ETHEREUM: "Ethereum (erc20)",
  TRON: "Tron (trc20)",
  BINANCE_SMART_CHAIN: "Binance smart chain (bep20)",
};

export function PaymentMethods() {
  const [depositState, setDepositState] = useState([]);
  const [withdrawState, setWithdrawState] = useState([]);

  useQuery(
    "payment-methods1",
    async () => {
      return PaymentMethodsApi.getReplenishPaymentMethods();
    },
    {
      onSuccess: (data) => {
        setDepositState(data as any);
      },
      retry: false,
    }
  );

  useQuery(
    "payment-methods2",
    async () => {
      return PaymentMethodsApi.getWithdrawPaymentMethods();
    },
    {
      onSuccess: (data) => {
        setWithdrawState(data as any);
      },
      retry: false,
    }
  );

  const { mutate: enableReplenishPaymentMethod } = useMutation(
    (dto: { ticker: string; blockchain: string }) => {
      return PaymentMethodsApi.enableReplenishPaymentMethod(dto);
    }
  );

  const { mutate: disableReplenishPaymentMethod } = useMutation(
    (dto: { ticker: string; blockchain: string }) => {
      return PaymentMethodsApi.disableReplenishPaymentMethod(dto);
    }
  );

  const { mutate: enableWithdrawPaymentMethod } = useMutation(
    (dto: { ticker: string; blockchain: string }) => {
      return PaymentMethodsApi.enableWithdrawPaymentMethod(dto);
    }
  );

  const { mutate: disableWithdrawPaymentMethod } = useMutation(
    (dto: { ticker: string; blockchain: string }) => {
      return PaymentMethodsApi.disableWithdrawPaymentMethod(dto);
    }
  );

  return (
    <Grid container spacing={2} paddingTop={2}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography
              style={{ marginBottom: 10 }}
              variant="h6"
              component="div"
            >
              Deposit payment methods
            </Typography>
            {depositState.map((ds: any, i: number) => {
              return (
                <FormGroup key={ds.ticker + i + "deposit"}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => {
                          const newState: any = [...depositState];
                          newState[i] = {
                            ...(depositState[i] as any),
                            enabled: !(depositState[i] as any).enabled,
                          };
                          if ((depositState[i] as any).enabled) {
                            disableReplenishPaymentMethod({
                              ticker: ds.ticker,
                              blockchain: ds.blockchain,
                            });
                          } else {
                            enableReplenishPaymentMethod({
                              ticker: ds.ticker,
                              blockchain: ds.blockchain,
                            });
                          }
                          setDepositState(newState);
                        }}
                        checked={ds.enabled}
                      />
                    }
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          style={{ width: 20, marginRight: 5 }}
                          src={tickerImg[ds.ticker]}
                        />{" "}
                        in {(Blockchain as any)[ds.blockchain]}
                      </div>
                    }
                  />
                </FormGroup>
              );
            })}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography
              style={{ marginBottom: 10 }}
              variant="h6"
              component="div"
            >
              Withdraw payment methods
            </Typography>
            {withdrawState.map((ds: any, i: number) => {
              return (
                <FormGroup key={ds.ticker + i + "withdraw"}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => {
                          const newState: any = [...withdrawState];
                          newState[i] = {
                            ...(withdrawState[i] as any),
                            enabled: !(withdrawState[i] as any).enabled,
                          };
                          if ((withdrawState[i] as any).enabled) {
                            disableWithdrawPaymentMethod({
                              ticker: ds.ticker,
                              blockchain: ds.blockchain,
                            });
                          } else {
                            enableWithdrawPaymentMethod({
                              ticker: ds.ticker,
                              blockchain: ds.blockchain,
                            });
                          }
                          setWithdrawState(newState);
                        }}
                        checked={ds.enabled}
                      />
                    }
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          style={{ width: 20, marginRight: 5 }}
                          src={tickerImg[ds.ticker]}
                        />{" "}
                        in {(Blockchain as any)[ds.blockchain]}
                      </div>
                    }
                  />
                </FormGroup>
              );
            })}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
