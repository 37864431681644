import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  DateField,
  FunctionField,
  EditButton,
} from "react-admin";
import Chip from "@mui/material/Chip";
import { ranks } from "../../constants";

export function UsersList(props: any) {
  return (
    <List
      filters={[
        <SearchInput
          placeholder="Search by prettyId or email"
          source="q"
          alwaysOn
        />,
      ]}
      exporter={false}
      {...props}
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="userId" label="Id" sortBy="id" />
        <TextField source="prettyId" label="PrettyId" sortBy="prettyId" />
        <TextField source="name" />
        <DateField source="createdAt" />
        <FunctionField
          source="country"
          render={(record: { country: string }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ width: 15, marginRight: 3, marginBottom: -2 }}
                src={`https://flagcdn.com/${record.country.toLowerCase()}.svg`}
                alt=""
                id="popup_referral_country_flag"
              ></img>
              {record.country.toLowerCase()}
            </div>
          )}
        />
        <TextField source="phone" />
        <TextField sortable={false} source="totalDeposit" />
        <TextField sortable={false} source="totalWithdraw" />
        <TextField sortable={false} source="personalInvest" />
        <TextField source="accrualTurnover" />
        <TextField source="bonusTurnover" />
        <TextField sortable={false} source="referrals" />
        <FunctionField
          sortable={false}
          source="level"
          label="Level"
          render={(record: { level: string; levelPercent: string }) => (
            <div
              style={{
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
              }}
            >
              {record.level}
              <Chip
                style={{ marginLeft: 3 }}
                size="small"
                label={`${record.levelPercent}%`}
                color="primary"
              />
            </div>
          )}
        />
        <FunctionField
          source="referralRank"
          label="Referral rank"
          render={(record: { referralRank: string }) => (
            <div style={{ whiteSpace: "nowrap" }}>
              {record.referralRank} ({ranks[Number(record.referralRank)]})
            </div>
          )}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
}
