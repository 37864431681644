import { fetch } from "../fetch";

export const getWithdrawalChartData = async (
  startDate: number,
  endDate: number
): Promise<
  Array<{
    createdAt: Date;
    amount: string;
  }>
> => {
  const response = await fetch(
    process.env.REACT_APP_API_URL +
      `/admin/withdrawal-chart?startDate=${startDate}&endDate=${endDate}`,
    {
      method: "GET",
    }
  );
  return response.json;
};

export const getReplenishmentChartData = async (
  startDate: number,
  endDate: number
): Promise<
  Array<{
    createdAt: Date;
    amount: string;
  }>
> => {
  const response = await fetch(
    process.env.REACT_APP_API_URL +
      `/admin/replenishment-chart?startDate=${startDate}&endDate=${endDate}`,
    {
      method: "GET",
    }
  );
  return response.json;
};

export const getInvestmentChartData = async (
  startDate: number,
  endDate: number
): Promise<
  Array<{
    createdAt: Date;
    amount: string;
    fee: string;
  }>
> => {
  const response = await fetch(
    process.env.REACT_APP_API_URL +
      `/admin/investment-chart?startDate=${startDate}&endDate=${endDate}`,
    {
      method: "GET",
    }
  );
  return response.json;
};

export const getDividendsStats = async (
  startDate: number,
  endDate: number
): Promise<{
  total: string;
  usdTotal: string;
}> => {
  const response = await fetch(
    process.env.REACT_APP_API_URL +
      `/admin/dividends-stats?startDate=${startDate}&endDate=${endDate}`,
    {
      method: "GET",
    }
  );
  return response.json;
};
