export const paymentKinds = [
  { value: "REPLENISHMENT", label: "Replenishment" },
  { value: "WITHDRAWAL", label: "Withdrawal" },
  { value: "INVESTMENT", label: "Deposit" },
  { value: "DIVIDEND", label: "Deposits profit" },
  { value: "CLAIMED_DIVIDEND", label: "ITE Unlock" },
  { value: "REFERRAL_FUND", label: "Referral reward" },
  { value: "SWAP", label: "ITE Exchange" },
  { value: "TRANSFER", label: "Transfer funds" },
  { value: "REFERRAL_RANK_BONUS", label: "Extra Bonus" },
];

export const paymentStatuses = [
  { value: "DONE", label: "Done" },
  { value: "PENDING", label: "Pending" },
  { value: "FAILED", label: "Failed" },
  { value: "CREATED", label: "Created" },
];

export const paymentBlockchains = [
  { value: "ETHEREUM", label: "Etherum (erc20)" },
  { value: "BITCOIN", label: "Bitcoin" },
  { value: "TRON", label: "Tron (trc20)" },
  { value: "BINANCE_SMART_CHAIN", label: "Binance smart chain (bep20)" },
];
