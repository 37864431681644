import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import { useNotify, useRecordContext } from "react-admin";
import { useMutation } from "react-query";
import { UsersApi } from "../../api";

export function FreezeUser() {
  const record = useRecordContext();
  const [checked, setChecked] = useState(!!record.freezed);

  const notify = useNotify();

  const { mutate: freezeUser } = useMutation(
    () => {
      return UsersApi.freezeUser(record.id as string);
    },
    {
      onSuccess: () =>
        notify("User successfullly freezed", { type: "success" }),
    }
  );

  const { mutate: unfreezeUser } = useMutation(
    () => {
      return UsersApi.unfreezeUser(record.id as string);
    },
    {
      onSuccess: () =>
        notify("User successfullly unfreezed", { type: "success" }),
    }
  );

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={() => {
              if (!checked) {
                freezeUser();
              } else {
                unfreezeUser();
              }
              setChecked(!checked);
            }}
          />
        }
        label="Freezed"
      />
    </FormGroup>
  );
}
