import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  DateField,
  FunctionField,
} from "react-admin";
import { PaymentsSidebar } from "./PaymentsSidebar";

export function PaymentsList() {
  return (
    <List
      filters={[
        <SearchInput placeholder="Search by user id" source="q" alwaysOn />,
      ]}
      exporter={false}
      aside={<PaymentsSidebar />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField sortable={false} source="id" />
        <TextField sortable={false} source="userId" />
        <FunctionField
          sortable={false}
          source="statusName"
          label="Status"
          render={(record: { statusName: string; statusColor: string }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: 5,
                  height: 5,
                  borderRadius: "50%",
                  backgroundColor: record.statusColor,
                  marginRight: 3,
                }}
              />
              {record.statusName}
            </div>
          )}
        />
        <TextField sortable={false} source="kindName" label="Kind" />
        <FunctionField
          sortable={false}
          source="amount"
          render={(record: {
            amount: string;
            negative?: boolean;
            positive?: boolean;
          }) => (
            <div
              style={{
                color: record.positive
                  ? "green"
                  : record.negative
                  ? "red"
                  : "black",
                whiteSpace: "nowrap",
              }}
            >
              {record.positive ? "+" : ""}
              {record.amount}
            </div>
          )}
        />
        <TextField sortable={false} source="fee" />
        <DateField sortable={false} source="date" showTime={true} />
        <TextField sortable={false} source="ticker" />
        <TextField sortable={false} source="blockchain" />
        <TextField sortable={false} source="address" />
        <TextField sortable={false} source="hash" />
        <TextField sortable={false} source="asset" />
        <TextField sortable={false} source="targetAsset" />
        <TextField sortable={false} source="price" />
      </Datagrid>
    </List>
  );
}
