import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import { WithdrawalChart } from "./WithdrawalChart";
import { ReplenishmentChart } from "./ReplenishmentChart";
import { InvestmentsChart } from "./InvestmentsChart";
import { Balances } from "./Balances";
import { Resource } from "react-admin";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { DashboardApi } from "../../api";
import { useQuery } from "react-query";

export function Dashboard() {
  useEffect(() => {
    const xpath = "//div[text()='MUI X Missing license key']";
    const matchingElement = document.evaluate(
      xpath,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    );
    if (matchingElement && matchingElement.singleNodeValue) {
      matchingElement.singleNodeValue.textContent = ""
    }
  }, []);
  const lastMonday = new Date();
  lastMonday.setDate(lastMonday.getDate() - ((lastMonday.getDay() + 6) % 7));
  lastMonday.setUTCHours(0, 0, 0, 0);
  const monthFirstDay = new Date();
  monthFirstDay.setDate(1);
  monthFirstDay.setUTCHours(0, 0, 0, 0);

  const [startDate, setStartDate] = useState(lastMonday.getTime());
  const [endDate, setEndDate] = useState(Date.now());

  const [investmentChartData, setInvestmentChartData] = useState(
    [] as Array<{ createdAt: Date; amount: string; fee: string }>
  );
  const { refetch: fetchInvestmentChartData } = useQuery(
    "dashbaord1",
    async () => {
      return DashboardApi.getInvestmentChartData(startDate, endDate);
    },
    {
      onSuccess: (data) => {
        setInvestmentChartData(data as any);
      },
      retry: false,
    }
  );
  const [withdrawalChartData, setWithdrawalChartData] = useState(
    [] as Array<{ createdAt: Date; amount: string }>
  );
  const { refetch: fetchWithdrawalChartData } = useQuery(
    "dashbaord2",
    async () => {
      return DashboardApi.getWithdrawalChartData(startDate, endDate);
    },
    {
      onSuccess: (data) => {
        setWithdrawalChartData(data as any);
      },
      retry: false,
    }
  );
  const [replenishmentChartData, setReplenishmentChartData] = useState(
    [] as Array<{ createdAt: Date; amount: string }>
  );
  const { refetch: fetchReplenishmentChartData } = useQuery(
    "dashbaord3",
    async () => {
      return DashboardApi.getReplenishmentChartData(startDate, endDate);
    },
    {
      onSuccess: (data) => {
        setReplenishmentChartData(data as any);
      },
      retry: false,
    }
  );
  const [dividendsStats, setDividendsStats] = useState({
    total: "0.00",
    usdTotal: "0.00",
  });
  const { refetch: fetchDividendsStats } = useQuery(
    "dashbaord4",
    async () => {
      return DashboardApi.getDividendsStats(startDate, endDate);
    },
    {
      onSuccess: (data) => {
        setDividendsStats(data as any);
      },
      retry: false,
    }
  );

  useEffect(() => {
    fetchInvestmentChartData();
    fetchWithdrawalChartData();
    fetchReplenishmentChartData();
    fetchDividendsStats();
  }, [endDate, startDate]);

  const totalReplenish = replenishmentChartData.reduce(
    (acc: string, value) =>
      (parseFloat(acc) + parseFloat(value.amount)).toString(),
    "0"
  );
  const totalWithdraw = withdrawalChartData.reduce(
    (acc: string, value) =>
      (parseFloat(acc) + parseFloat(value.amount)).toString(),
    "0"
  );
  const totalInvest = investmentChartData.reduce(
    (acc: string, value) =>
      (parseFloat(acc) + parseFloat(value.amount)).toString(),
    "0"
  );
  const totalInvestFee = investmentChartData.reduce(
    (acc: string, value) =>
      (parseFloat(acc) + parseFloat(value.fee)).toString(),
    "0"
  );

  return (
    <Grid container spacing={2} paddingTop={2}>
      <Grid item xs={12} md={6} style={{ display: "flex" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangeCalendar
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={([start, end]) => {
              const startDate = new Date(dayjs(start).toDate().getTime());
              const endDate = new Date(
                dayjs(end || start)
                  .toDate()
                  .getTime()
              );
              setStartDate(startDate.getTime());
              setEndDate(endDate.getTime());
            }}
            calendars={1}
          />
        </LocalizationProvider>
        <div>
          <div>
            <Button
              style={{ marginRight: 10, marginBottom: 10 }}
              variant="contained"
              size="small"
              onClick={() => {
                setStartDate(Date.now());
                setEndDate(Date.now());
              }}
            >
              Today
            </Button>
            <Button
              style={{ marginBottom: 10 }}
              variant="contained"
              size="small"
              onClick={() => {
                setStartDate(Date.now() - 24 * 60 * 60 * 1000);
                setEndDate(Date.now() - 24 * 60 * 60 * 1000);
              }}
            >
              Yesterday
            </Button>
          </div>
          <div>
            <Button
              style={{ marginRight: 10, marginBottom: 10 }}
              variant="contained"
              size="small"
              onClick={() => {
                setStartDate(lastMonday.getTime());
                setEndDate(Date.now());
              }}
            >
              This week
            </Button>
            <Button
              style={{ marginBottom: 10 }}
              variant="contained"
              size="small"
              onClick={() => {
                setStartDate(lastMonday.getTime() - 7 * 24 * 60 * 60 * 1000);
                setEndDate(lastMonday.getTime());
              }}
            >
              Last week
            </Button>
          </div>
          <div>
            <Button
              style={{ marginRight: 10, marginBottom: 10 }}
              variant="contained"
              size="small"
              onClick={() => {
                setStartDate(monthFirstDay.getTime());
                setEndDate(Date.now());
              }}
            >
              This month
            </Button>
            <Button
              style={{ marginBottom: 10 }}
              variant="contained"
              size="small"
              onClick={() => {
                setStartDate(
                  monthFirstDay.getTime() - 30 * 24 * 60 * 60 * 1000
                );
                setEndDate(monthFirstDay.getTime());
              }}
            >
              Last month
            </Button>
          </div>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setStartDate(0);
              setEndDate(Date.now());
            }}
          >
            All time
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <Card
          style={{
            marginBottom: 10,
            background: "linear-gradient(rgba(33, 150, 243, 0.4), transparent)",
          }}
        >
          <CardContent>
            <Typography
              style={{ marginBottom: 5 }}
              variant="h6"
              component="div"
            >
              Total Invested: <b>{parseFloat(totalInvest).toFixed(2)} USDT</b>
            </Typography>
            <Typography variant="h6" component="div">
              Total Invest Fee:{" "}
              <b>{parseFloat(totalInvestFee).toFixed(2)} USDT</b>
            </Typography>
          </CardContent>
        </Card>
        <Card
          style={{
            background: "linear-gradient(rgba(0, 128, 0, 0.4), transparent)",
          }}
        >
          <CardContent>
            <Typography variant="h6" component="div">
              Total Replenish:{" "}
              <b>{parseFloat(totalReplenish).toFixed(2)} USDT</b>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3}>
        <Card style={{ marginBottom: 10 }}>
          <CardContent>
            <Typography
              style={{ marginBottom: 5 }}
              variant="h6"
              component="div"
            >
              Deposit accruals: <b>{dividendsStats.total} ITE</b>
            </Typography>
            <Typography variant="h6" component="div">
              Converted: <b>{dividendsStats.usdTotal} USDT</b>
            </Typography>
          </CardContent>
        </Card>
        <Card
          style={{
            background: "linear-gradient(rgba(255, 0, 0, 0.4), transparent)",
          }}
        >
          <CardContent>
            <Typography variant="h6" component="div">
              Total Withdraw: <b>{parseFloat(totalWithdraw).toFixed(2)} USDT</b>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography
              style={{ marginBottom: 10 }}
              variant="h6"
              component="div"
            >
              Withdrawals
            </Typography>
            <WithdrawalChart data={withdrawalChartData} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography
              style={{ marginBottom: 10 }}
              variant="h6"
              component="div"
            >
              Replenishments
            </Typography>
            <ReplenishmentChart data={replenishmentChartData} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent style={{ position: "relative" }}>
            <Typography
              style={{ marginBottom: 10 }}
              variant="h6"
              component="div"
            >
              Deposits
            </Typography>
            <InvestmentsChart data={investmentChartData} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography
              style={{ marginBottom: 10 }}
              variant="h6"
              component="div"
            >
              Balances
            </Typography>
            <Resource name="balances" list={Balances} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
