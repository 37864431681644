import {
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useRecordContext,
} from "react-admin";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { UsersActions } from "./UsersActions";
import { UserCard } from "./UserCard";

function UserEditToolbar(props: ToolbarProps) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}

function UserCommentForm() {
  const record = useRecordContext();
  return (
    <SimpleForm toolbar={<UserEditToolbar />}>
      <TextInput
        style={{ width: "100%" }}
        label="Comment"
        source="comment"
        value={record.comment}
      />
    </SimpleForm>
  );
}

export function UsersEdit() {
  return (
    <>
      <Grid container spacing={2} paddingTop={2}>
        <Grid item xs={12} md={4} style={{ paddingTop: 0 }}>
          <Edit title={" "} actions={false}>
            <UserCard />
          </Edit>
        </Grid>
        <Grid item xs={12} md={4} style={{ paddingTop: 0 }}>
          <Edit actions={false} title={" "}>
            <Card>
              <Typography
                style={{ marginTop: 10, marginLeft: 15 }}
                variant="h6"
                component="div"
              >
                Actions
              </Typography>
              <CardContent>
                <UsersActions />
              </CardContent>
            </Card>
          </Edit>
        </Grid>
        <Grid item xs={12} md={4} style={{ paddingTop: 0 }}>
          <Edit redirect={false} title="Edit User" actions={false}>
            <Typography
              style={{ marginTop: 10, marginLeft: 15 }}
              variant="h6"
              component="div"
            >
              Set Comment
            </Typography>
            <UserCommentForm />
          </Edit>
        </Grid>
      </Grid>
    </>
  );
}
