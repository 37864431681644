import { fetch } from "../fetch";

export const getReplenishPaymentMethods = async (): Promise<
  Array<{
    ticker: string;
    blockchain: string;
    enabled?: boolean;
  }>
> => {
  const response = await fetch(
    process.env.REACT_APP_API_URL + "/admin/payment-methods/replenish",
    {
      method: "GET",
    }
  );
  return response.json;
};

export const getWithdrawPaymentMethods = async (): Promise<
  Array<{
    ticker: string;
    blockchain: string;
    enabled?: boolean;
  }>
> => {
  const response = await fetch(
    process.env.REACT_APP_API_URL + "/admin/payment-methods/withdraw",
    {
      method: "GET",
    }
  );
  return response.json;
};

export const enableReplenishPaymentMethod = async (dto: {
  ticker: string;
  blockchain: string;
}): Promise<void> => {
  await fetch(
    process.env.REACT_APP_API_URL + "/admin/payment-methods/replenish/enable",
    {
      method: "POST",
      body: JSON.stringify(dto),
    }
  );
};

export const disableReplenishPaymentMethod = async (dto: {
  ticker: string;
  blockchain: string;
}): Promise<void> => {
  await fetch(
    process.env.REACT_APP_API_URL + "/admin/payment-methods/replenish/disable",
    {
      method: "POST",
      body: JSON.stringify(dto),
    }
  );
};

export const enableWithdrawPaymentMethod = async (dto: {
    ticker: string;
    blockchain: string;
  }): Promise<void> => {
    await fetch(
      process.env.REACT_APP_API_URL + "/admin/payment-methods/withdraw/enable",
      {
        method: "POST",
        body: JSON.stringify(dto),
      }
    );
  };
  
  export const disableWithdrawPaymentMethod = async (dto: {
    ticker: string;
    blockchain: string;
  }): Promise<void> => {
    await fetch(
      process.env.REACT_APP_API_URL + "/admin/payment-methods/withdraw/disable",
      {
        method: "POST",
        body: JSON.stringify(dto),
      }
    );
  };
