import { FilterList, FilterListItem } from "react-admin";
import CategoryIcon from "@mui/icons-material/LocalOffer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { paymentKinds, paymentStatuses } from "../../constants";

export function PaymentsSidebar() {
  return (
    <>
      <Card sx={{ ml: 2, mt: 8, width: 300 }}>
        <CardContent>
          <FilterList label="Kind" icon={<CategoryIcon />}>
            {paymentKinds.map((k) => (
              <FilterListItem label={k.label} value={{ kind: k.value }} />
            ))}
          </FilterList>
          <FilterList label="Status" icon={<CategoryIcon />}>
            {paymentStatuses.map((k) => (
              <FilterListItem label={k.label} value={{ status: k.value }} />
            ))}
          </FilterList>
        </CardContent>
      </Card>
    </>
  );
}
