import { List, Datagrid, TextField } from "react-admin";

export function Balances(props: any) {
  return (
    <List
      filters={false}
      actions={null}
      exporter={false}
      pagination={false}
      {...props}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" sortable={false} />
        <TextField source="amount" sortable={false} />
        <TextField source="ticker" sortable={false} />
      </Datagrid>
    </List>
  )
}
