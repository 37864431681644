import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import { useNotify, useRecordContext } from "react-admin";
import { useMutation } from "react-query";
import { UsersApi } from "../../api";

export function BlockUser() {
  const record = useRecordContext();
  const [checked, setChecked] = useState(!!record.blocked);

  const notify = useNotify();

  const { mutate: blockUser } = useMutation(
    () => {
      return UsersApi.blockUser(record.id as string);
    },
    {
      onSuccess: () =>
        notify("User successfullly blocked", { type: "success" }),
    }
  );

  const { mutate: unblockUser } = useMutation(
    () => {
      return UsersApi.unblockUser(record.id as string);
    },
    {
      onSuccess: () =>
        notify("User successfullly unblocked", { type: "success" }),
    }
  );

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={() => {
              if (!checked) {
                blockUser();
              } else {
                unblockUser();
              }
              setChecked(!checked);
            }}
          />
        }
        label="Blocked"
      />
    </FormGroup>
  );
}
