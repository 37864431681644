export const ranks = [
  "No status",
  "Beginner",
  "Bronze partner",
  "Silver partner",
  "Golden Partner",
  "Platinum Partner",
  "TOP leader",
  "Golden leader",
  "Platinum leader",
  "Regional director",
  "Silver director",
  "Golden director",
  "Platinum director",
  "Shareholder",
  "Platinum Shareholder",
  "Founder",
];
