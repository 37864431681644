import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export function InvestmentsChart({
  data,
}: {
  data: Array<{amount: string; createdAt: Date}>
}) {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: "area",
        },
        plotOptions: {
          series: {
            pointPlacement: "on",
          },
          area: {
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, "#2196f3"],
                [1, "#f4f4f4"],
              ],
            },
          },
        },
        title: null,
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          type: "datetime",
          title: null,
          categories: data.length
            ? data.map((el) =>
                new Date(el.createdAt).toLocaleDateString("en-US")
              )
            : [],
        },
        yAxis: {
          title: null,
        },
        tooltip: {
          headerFormat: null,
        },
        series: [
          {
            name: "Deposited",
            data: data.length
              ? data.map((el) => [el.createdAt, Number(el.amount)])
              : [],
          },
        ],
      }}
    />
  );
}
