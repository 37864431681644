import { Title } from "react-admin";
import { PaymentMethods } from "./PaymentsMethods";

export function Settings() {
  return (
    <div>
      <Title title={'Settings'} />
      <PaymentMethods></PaymentMethods>
    </div>
  );
}
