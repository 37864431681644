import { useRecordContext } from "react-admin";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ranks } from "../../constants";

export function UserCard() {
  const user = useRecordContext();
  return (
    <Card>
      <CardContent>
        <Typography variant="h4" component="div">
          {user.name}
        </Typography>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            ID:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>{user.id}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            PrettyID:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>{user.prettyId}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            Phone:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>{user.phone}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            Created:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>
            {new Date(user.createdAt).toLocaleDateString("en-US")}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            Country:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ width: 15, marginRight: 3, marginBottom: -2 }}
                src={`https://flagcdn.com/${user.country.toLowerCase()}.svg`}
                alt=""
                id="popup_referral_country_flag"
              ></img>
              {user.country.toLowerCase()}
            </div>
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            Personal invest:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>
            {user.personalInvest} USDT
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            Accrual turnover:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>
            {user.accrualTurnover} USDT
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            Bonus turnover:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>
            {user.bonusTurnover} USDT
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            Total withdraw:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>
            {user.totalWithdraw} USDT
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            Total deposit:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>
            {user.totalReplenish} USDT
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            Referrals:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>{user.referralsCount}</Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14" }}>
            Rank:{" "}
          </Typography>
          <Typography sx={{ fontSize: "16" }}>
            {ranks[user.referralRank]}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
