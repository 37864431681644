import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route } from "react-router-dom";
import authProvider from "../authProvider";
import dataProvider from "../dataProvider";
import { Dashboard } from "./dashboard";
import { Layout } from "./Layout";
import { Payments } from "./payments";
import { UsersEdit } from "./users/UsersEdit";
import { UsersList } from "./users/UsersList";
import UsersIcon from "@mui/icons-material/People";
import { Settings } from "./settings";
import { PaymentsList } from "./payments/PaymentsList";
import { PaymentsCreate } from "./payments/PaymentsCreate";

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    layout={Layout}
  >
    <Resource
      icon={UsersIcon}
      name="users"
      list={UsersList}
      edit={UsersEdit}
      hasCreate={false}
      hasShow={false}
      hasEdit
    />
    <Resource name="payments" list={PaymentsList} create={PaymentsCreate} />
    <CustomRoutes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/settings" element={<Settings />} />
      {/* <Route path="/payments/create" element={<PaymentsCreate />} /> */}
    </CustomRoutes>
  </Admin>
);

export default App;
