import { useRecordContext, useNotify } from "react-admin";
import { BlockUser } from "./BlockUser";
import { FreezeUser } from "./FreezeUser";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { UsersApi } from "../../api";

export function UsersActions() {
  const record: { id: string; email: string } = useRecordContext();
  const navigate = useNavigate();

  const notify = useNotify();

  const { mutate: resetRateLimits } = useMutation(
    () => {
      return UsersApi.resetUserRateLimits(record.id, record.email);
    },
    {
      onSuccess: () =>
        notify("Rate limits successfullly reseted", { type: "success" }),
    }
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <FreezeUser />
      <BlockUser />
      <Button
        style={{ marginTop: 5, width: 200 }}
        variant="contained"
        size="small"
        color="success"
        onClick={() =>
          navigate("/payments/create", {
            state: { userId: record.id, kind: "REPLENISHMENT" },
          })
        }
      >
        Manual deposit
      </Button>
      <Button
        style={{ marginTop: 5, width: 200 }}
        variant="contained"
        size="small"
        color="error"
        onClick={() =>
          navigate("/payments/create", {
            state: { userId: record.id, kind: "WITHDRAWAL" },
          })
        }
      >
        Manual withdraw
      </Button>
      <Button
        style={{ marginTop: 5, width: 200 }}
        variant="contained"
        size="small"
        onClick={() => resetRateLimits()}
      >
        Reset rate limits
      </Button>
    </div>
  );
}
