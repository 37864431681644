import { fetch } from "../fetch";

export const freezeUser = async (userId: string): Promise<void> => {
  await fetch(
    process.env.REACT_APP_API_URL + "/admin/users/" + userId + "/freeze",
    {
      method: "POST",
      body: JSON.stringify({}),
    }
  );
};

export const unfreezeUser = async (userId: string): Promise<void> => {
  await fetch(
    process.env.REACT_APP_API_URL + "/admin/users/" + userId + "/unfreeze",
    {
      method: "POST",
      body: JSON.stringify({}),
    }
  );
};

export const blockUser = async (userId: string): Promise<void> => {
  await fetch(
    process.env.REACT_APP_API_URL + "/admin/users/" + userId + "/block",
    {
      method: "POST",
      body: JSON.stringify({}),
    }
  );
};

export const unblockUser = async (userId: string): Promise<void> => {
  await fetch(
    process.env.REACT_APP_API_URL + "/admin/users/" + userId + "/unblock",
    {
      method: "POST",
      body: JSON.stringify({}),
    }
  );
};

export const resetUserRateLimits = async (
  userId: string,
  email: string
): Promise<void> => {
  await fetch(process.env.REACT_APP_API_URL + "/admin/rate-limits/reset", {
    method: "POST",
    body: JSON.stringify({ userId, email }),
  });
};
