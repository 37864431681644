import { Menu as MenuLib, Layout as LayoutLib, LayoutProps } from "react-admin";
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';

function Menu() {
  return (
    <MenuLib>
      <MenuLib.DashboardItem leftIcon={<HomeIcon />} />
      <MenuLib.ResourceItems />
      {/* <MenuLib.Item to="/payments" primaryText="Payments" leftIcon={<ReceiptIcon />}/> */}
      <MenuLib.Item to="/settings" primaryText="Settings" leftIcon={<SettingsIcon />}/>
    </MenuLib>
  );
}

export function Layout(props: LayoutProps) {
    return <LayoutLib {...props} menu={Menu} />
}
